<template>
    <page :title="pageTitle">
        <div class="row" v-if="companies && companies.length > 0">
            <div class="col-12">
                <input-field type="select" label="Armator" v-model="company_id" name="company_id">
                    <option value="">- wybierz armatora -</option>
                    <option v-for="company in companies" :key="company.id" :value="company.id">{{company.punt_company_name}}</option>
                </input-field>
            </div>
        </div>
        <div class="panel" v-if="company_id">
            <div class="panel-heading"><h2>Filtry</h2></div>
            <div class="panel-body">
                <div class="row">
                    <div class="col-4" v-if="yachtCategories && yachtCategories.length > 0">
                        <input-field name="filter_yacht_category_id" type="other" label="Typ" :label-class="'h-auto'" v-model="filter_yacht_category_id">
                            <v-select placeholder="- wybierz typ -" multiple v-model="filter_yacht_category_id" :options="yachtCategories" :reduce="c => c.id" label="punt_yacht_category_name" class="fs-13px"></v-select>
                        </input-field>
                    </div>
                    <div class="col-4" v-if="countries && countries.length > 0">
                        <input-field name="filter_country_id" type="other" label="Kraj" :label-class="'h-auto'" v-model="filter_country_id">
                            <v-select placeholder="- wybierz kraj -" multiple v-model="filter_country_id" :options="countries" :reduce="c => c.id" label="punt_country_name" class="fs-13px"></v-select>
                        </input-field>
                    </div>
                    <div class="col-4" v-if="bases && bases.length > 0">
                        <input-field name="filter_base_id" type="other" label="Baza" :label-class="'h-auto'" v-model="filter_base_id" >
                            <v-select placeholder="- wybierz bazę -" multiple v-model="filter_base_id" :options="bases" :reduce="c => c.id" label="punt_base_name" class="fs-13px"></v-select>
                        </input-field>
                    </div>
                </div>
                <div class="row">
                    <div class="col-4">
                        <input-field name="filter_cabins" type="other" :label="'Ilość kabin'" v-model="filter_cabins" >
                            <vue-slider v-model="filter_cabins" :enable-cross="false" :dot-options="{tooltip: 'always'}" :tooltip-placement="'bottom'" :min="filter_cabins_range[0]" :max="filter_cabins_range[1]"></vue-slider>
                        </input-field>
                    </div>
                    <div class="col-4">
                        <input-field name="filter_length" type="other" label="Długość w stopach" v-model="filter_length" >
                            <vue-slider v-model="filter_length" :enable-cross="false" :dot-options="{tooltip: 'always'}" :tooltip-placement="'bottom'" :min="filter_length_range[0]" :max="filter_length_range[1]"></vue-slider>
                        </input-field>
                    </div>
                    <div class="col-4">
                        <input-field name="filter_build_year" type="other" label="Rok produkcji" v-model="filter_build_year">
                            <vue-slider v-model="filter_build_year" :enable-cross="false" :dot-options="{tooltip: 'always'}" :tooltip-placement="'bottom'" :min="filter_build_year_range[0]" :max="filter_build_year_range[1]"></vue-slider>
                        </input-field>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12 text-end">
                        <b-btn variant="default" @click="resetFilters" class="me-2">wyczyść filtry</b-btn>
                        <b-btn variant="success" @click="loadYachts" :disabled="!filterChanged">zastosuj</b-btn>
                    </div>
                </div>
            </div>
        </div>
        <div class="row" v-show="isBusy === true">
            <div class="col-12">loading....</div>
        </div>
        <div class="row">
            <div class="col-12">
                <busy v-if="isLoading"></busy>
                <table class="table table-bordered table-sm" v-if="isLoading === 0 && discounts !== null && yachts.length > 0">
                    <thead>
                    <tr>
                        <th>Jacht</th>
                        <th v-for="d in dates" :key="d.format('YYYY-MM-DD')" class="text-center">
                            {{d.format('DD')}}<br>{{d.format('MM')}}<br>{{d.format('YY')}}
                            <div class="form-check m-auto text-center">
                                <input type="checkbox" class="form-check-input" @click="(event) => onClickDate(d, event)" :checked="selDates[d.format('YYYY-MM-DD')]">
                            </div>
                        </th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="yacht in yachts" :key="yacht.id">
                        <td style="white-space: nowrap;" class="position-relative">
                            <div class="me-5">{{yacht.punt_yacht_model_name}} {{yacht.punt_yacht_name}} {{yacht.punt_yacht_build_year}}</div>
                            <div class="form-check yacht-check">
                                <input type="checkbox" class="form-check-input" @click="(event) => onClickYacht(yacht.id, event)"  :checked="selYachts[yacht.id]">
                            </div>
                        </td>
                        <td v-for="d in dates"
                            :key="yacht.id + '-' + d.format('YYYY-MM-DD')"
                            class="data-cell text-center"
                            :class="{'data-cell-active clickable': selDates[d.format('YYYY-MM-DD')] === true && (selYachts[yacht.id] === true || Object.keys(selYachts).length === 0)}"
                            @click="() => { if (selDates[d.format('YYYY-MM-DD')] === true && (selYachts[yacht.id] === true || Object.keys(selYachts).length === 0)) onClickDiscount();}"
                        >
                            {{ discounts[yacht.id][d.format('YYYY-MM-DD')]}}
                        </td>
                    </tr>
                    </tbody>
                </table>
                <h3 v-if="isLoading === 0 && discounts !== null && yachts.length == 0">Brak jachtów spełniających kryteria</h3>
            </div>
        </div>
    </page>
</template>

<script>
import moment from "moment";
import page from "@/pages/Common/Page.vue";
import axios from 'axios';
import DiscountChangeDialog from "@/pages/Discounts/DiscountChangeDialog.vue";
import InputField from "@/components/Forms/InputField.vue";
import Busy from "@/components/Common/Busy.vue";

export default {
    name: "DiscountsPage",
    components: {page, InputField, Busy},
    props: {
        pageTitle: {
            type: String,
            required: true
        },
        loadEndpoinUrl: {
            type: String,
            required: true
        },
        updateEndpointUrl: {
            type: String,
            required: true
        },
        discountFieldName: {
            type: String,
            required: true
        },
        discountFieldLabel: {
            type: String,
            required: false,
            default: 'Rabat'
        },
        discountFieldPrecision: {
            type: Number,
            required: false,
            default: 2
        }
    },
    data() {
        return {
            countries: null,
            bases: null,
            companies: null,
            yachtCategories: null,
            company_id: null,
            yachts: null,
            isLoading: 0,
            selYachts: {},
            selDates: {},
            selDatesRangeStart: null,
            selYachtsRangeStart: null,
            discounts: null,
            _discounts: null,
            isBusy: false,
            filter_country_id: [],
            filter_base_id: [],
            filter_cabins: [],
            filter_cabins_range: [1, 8],
            filter_length: [],
            filter_length_range: [20, 60],
            filter_build_year: [],
            filter_build_year_range: [2000, 0],
            filter_yacht_category_id: [],
            last_filters: [],
            filterChanged: false,
        }
    },
    computed: {
        dates() {
            let dates = [];

            let d = moment().day(6);

            while(d.isSameOrBefore(moment().add(1, 'year').endOf('year'), 'day')) {
                dates.push(moment(d));
                d.add(7, 'days');
            }
            this.selDates = {};
            return dates;
        }
    },
    watch: {
        company_id(newVal) {
            this.selDates = {};
            this.loadYachtsDiscounts(newVal);
        },
        filter_country_id: {
            deep: true,
            handler(newVal) {
                if (!this.filterChanged) {
                    this.filterChanged = JSON.stringify(this.last_filters.filter_country_id) != JSON.stringify(newVal);
                }
                this.loadBases();
            }
        },
        filter_base_id: {
            deep:true,
            handler(newVal) {
                if (!this.filterChanged) {
                    this.filterChanged = JSON.stringify(this.last_filters.filter_base_id) != JSON.stringify(newVal);
                }
            }
        },
        filter_cabins: {
            deep: true,
            handler(newVal) {
                if (!this.filterChanged) {
                    this.filterChanged = JSON.stringify(this.last_filters.filter_cabins) != JSON.stringify(newVal);
                }
            }
        },
        filter_length: {
            deep: true,
            handler(newVal) {
                if (!this.filterChanged) {
                    this.filterChanged = JSON.stringify(this.last_filters.filter_length) != JSON.stringify(newVal);
                }
            }
        },
        filter_build_year: {
            deep: true,
            handler(newVal) {
                if (!this.filterChanged) {
                    this.filterChanged = JSON.stringify(this.last_filters.filter_build_year) != JSON.stringify(newVal);
                }
            }
        },
        filter_yacht_category_id: {
            deep: true,
            handler(newVal) {
                if (!this.filterChanged) {
                    this.filterChanged = JSON.stringify(this.last_filters.filter_yacht_category_id) != JSON.stringify(newVal);
                }
            }
        },
    },
    methods: {

        getDiscountValue(yacht, date) {
            if (this._discounts[yacht.id] === undefined) {
                return '-';
            }
            const finded = this._discounts[yacht.id].find(d => d.punt_price_date_from === date.format('YYYY-MM-DD'))
            if (!finded) {
                return '-';
            }
            return finded[this.discountFieldName];
        },
        onClickDate(date, event) {
            if (this.selDatesRangeStart && event.shiftKey) {
                this.selectDatesRange(this.selDatesRangeStart, date);
                this.selDatesRangeStart = null;
            } else {
                let formatedDate = date.format('YYYY-MM-DD');
                this.selDates[formatedDate] !== undefined ? this.selDates[formatedDate] = !this.selDates[formatedDate] : this.selDates[formatedDate] = true;
                if (event.shiftKey) {
                    this.selDatesRangeStart = moment(date);
                } else {
                    this.selDatesRangeStart = null;
                }
            }
            this.$forceUpdate();
        },
        selectDatesRange(dateStart, dateEnd) {
            console.log('SELECT DATES RANGE')
            var dateFrom = dateStart;
            var dateTo = dateEnd;
            if (dateFrom.isAfter(dateEnd)) {
                dateFrom = dateEnd;
                dateTo = dateStart;
            }

            this.dates.forEach(d => {
                if (d.isSameOrAfter(dateFrom, 'day') && d.isSameOrBefore(dateTo, 'day') && !d.isSame(dateStart, 'day')) {
                    let formatedDate = d.format('YYYY-MM-DD');
                    this.selDates[formatedDate] !== undefined ? this.selDates[formatedDate] = !this.selDates[formatedDate] : this.selDates[formatedDate] = true;
                }
            })
        },
        onClickYacht(yacht_id, e) {
            if (this.selYachtsRangeStart && e.shiftKey) {
                this.selectYachtsRange(this.selYachtsRangeStart, yacht_id);
                this.selYachtsRangeStart = null;
            } else {
                this.selYachts[yacht_id] === true ? delete this.selYachts[yacht_id] : this.selYachts[yacht_id] = true;
                if (e.shiftKey) {
                    this.selYachtsRangeStart = yacht_id;
                } else {
                    this.selYachtsRangeStart = null;
                }
            }
            this.$forceUpdate();
        },
        selectYachtsRange(yachtIdStart, yachtIdEnd) {
            let isSelecting = false;
            this.yachts.forEach(y => {
                if (y.id === yachtIdStart || y.id === yachtIdEnd) {
                    if (isSelecting && y.id != yachtIdStart) {
                        this.selYachts[y.id] === true ? delete this.selYachts[y.id] : this.selYachts[y.id] = true;
                    }
                    isSelecting= !isSelecting;
                }

                if (isSelecting && y.id != yachtIdStart) {
                    this.selYachts[y.id] === true ? delete this.selYachts[y.id] : this.selYachts[y.id] = true;
                }
            })
        },
        loadCompanies() {
            this.isLoading++;
            return axios.get('companies-all')
                .then(response => {
                    this.companies = response.data;
                })
                .finally(() => {
                    this.isLoading--;
                })
        },
        loadCountries() {
            this.isLoading++;
            return axios.get('countries-all')
                .then(response => {
                    this.countries = response.data;
                })
                .finally(() => {
                    this.isLoading--;
                })
        },
        loadYachtCategories() {
            this.isLoading++;
            return axios.get('yacht-categories-all')
                .then(response => {
                    this.yachtCategories = response.data;
                })
                .finally(() => {
                    this.isLoading--;
                })
        },
        loadBases() {
            let filters = [];
            this.filter_base_id = [];
            if (this.filter_country_id.length > 0) {
                filters.push('punt_country_ids|' + JSON.stringify(this.filter_country_id));
            }
            return axios.get('bases-all', {
                params: {
                    filters: JSON.stringify(filters)
                }
            })
                .then(response => {
                    this.bases = response.data;
                })
                .finally(() => {
                    this.filter_base_id = [];
                })
        },
        loadYachts(company_id) {
            return new Promise((resolve, reject) => {
                this.isLoading++;
                this.selYachts = {};

                let filters = [
                    'punt_company_id|'+this.company_id,
                    'punt_yacht_active|1',
                ];

                if (this.filter_yacht_category_id && this.filter_yacht_category_id.length > 0) {
                    filters.push('punt_yacht_category_ids|' + JSON.stringify(this.filter_yacht_category_id));

                }
                if (this.filter_country_id && this.filter_country_id.length > 0) {
                    filters.push('punt_country_ids|' + JSON.stringify(this.filter_country_id));

                }
                if (this.filter_base_id && this.filter_base_id.length > 0) {
                    filters.push('punt_base_ids|' + JSON.stringify(this.filter_base_id));

                }
                if (JSON.stringify(this.filter_cabins) !== JSON.stringify(this.filter_cabins_range)) {
                    filters.push('yacht_cabins_range|' + JSON.stringify(this.filter_cabins));

                }
                if (JSON.stringify(this.filter_length) !== JSON.stringify(this.filter_length_range)) {
                    filters.push('yacht_lenght_feet_range|' + JSON.stringify(this.filter_length));

                }
                if (JSON.stringify(this.filter_build_year) !== JSON.stringify(this.filter_build_year_range)) {
                    filters.push('yacht_build_year_range|' + JSON.stringify(this.filter_build_year));
                }


                this.last_filters.filter_country_id = this.filter_country_id;
                this.last_filters.filter_base_id = this.filter_base_id
                this.last_filters.filter_cabins = this.filter_cabins;
                this.last_filters.filter_length = this.filter_length;
                this.last_filters.filter_build_year = this.filter_build_year;
                this.last_filters.filter_yacht_category_id = this.filter_yacht_category_id;

                this.filterChanged = false;
                axios.get('yachts-all', {
                    params: {
                        filters: JSON.stringify(filters)
                    }
                })
                    .then(response => {
                        this.yachts = response.data
                        resolve();
                    })
                    .catch((e) => {
                        reject(e);
                    })
                    .finally(() => {
                        this.isLoading--;
                    })
            })
        },
        loadDiscounts(company_id) {
            return new Promise((resolve, reject) => {
                this.isLoading++;
                this._discounts = null;
                axios.get(this.loadEndpoinUrl + '/' + company_id)
                    .then(response => {
                        this._discounts = response.data;
                        resolve();
                    })
                    .catch(e => {
                        reject(e);
                    })
                    .finally(() => {
                        this.isLoading--;
                    })

            });
        },
        fillDiscountsToShow() {
            this.isLoading++;
            this.discounts = {};
            let formatedDate = '';
            this.yachts.forEach(y => {
                this.discounts[y.id] = {};
                this.dates.forEach(d => {
                    formatedDate = d.format('YYYY-MM-DD');
                    this.discounts[y.id][formatedDate] = this.getDiscountValue(y, d);
                })
            })
            this.isLoading--;
        },

        loadYachtsDiscounts(company_id) {
            Promise.all([this.loadYachts(company_id), this.loadDiscounts(company_id)]).then(() => {
                this.fillDiscountsToShow();
            })
        },

        onClickDiscount() {
            var that = this;
            this.$modal.show(DiscountChangeDialog, {
                discountFieldLabel: this.discountFieldLabel,
                discountFieldPrecision: this.discountFieldPrecision,
                onSet(discount, dateValidFrom, dateValidTo) {
                    that.updateSelected(discount, dateValidFrom, dateValidTo);
                }
            }, {
                'adaptive': true,
                'height': 'auto',
            });
        },

        updateSelected(discount, dateValidFrom, dateValidTo) {
            this.isLoading++;
            return axios.post(this.updateEndpointUrl, {
                yachtsDatas: Object.keys(this.selYachts).length > 0 ? this.yachts.filter(y => this.selYachts[y.id] === true) : this.yachts,
                dates: Object.keys(this.selDates),
                discount: discount,
                dateValidFrom: dateValidFrom,
                dateValidTo: dateValidTo
            }).then(() => {
                this.loadYachtsDiscounts(this.company_id);
            }).finally(() => {
                this.isLoading--;
            })
        },

        resetFilters(reloadYachts = true) {
            this.filter_country_id = [];
            this.filter_base_id = [];
            this.filter_cabins = [...this.filter_cabins_range];
            this.filter_length = [...this.filter_length_range];
            this.filter_build_year = [...this.filter_build_year_range];
            this.filter_yacht_category_id = [];
            this.$forceUpdate();
            this.loadYachts();
        }
    },
    created() {
        this.filter_build_year_range[1] = moment().year() + 1;
        this.resetFilters(false)
        this.loadCompanies();
        this.loadCountries();
        this.loadBases();
        this.loadYachtCategories();
    }

}
</script>

<style scoped>

.data-cell {

}
.data-cell:hover {
    color: black;
    background-color: lightgray;
}
.data-cell-active {
    color: black;
    background-color: lightcoral;
}
.yacht-check {
    position: absolute;
    right: 0px;
    top:4px;
}
</style>